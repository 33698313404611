<template>
  <v-container
    fill-height
    fluid
    style="height: 100%"
    v-if="$store.state.auth.isAuthenticating"
  >
    <v-row class="text-center">
      <v-col cols="12">
        <v-progress-circular
          indeterminate
          color="primary"
          size="50"
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
  <div v-else>
    <!--<v-expansion-panels accordion class="expansion-panel">
      <v-expansion-panel>
        <v-expansion-panel-header> Map Typ </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-radio-group v-model="mapType" dense @change="mapTypeChanged">
            <v-radio
                v-for="(mapType, i) in mapTypes"
                :key="mapType.label + i"
                :label="mapType.label"
                :value="mapType.value"
            ></v-radio>
          </v-radio-group>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>-->

    <v-btn @click="resetFilter" small> Auswahl Zurücksetzen </v-btn>

    <div
      v-for="(mainCategory, i) in dividedCategories"
      :key="mainCategory.category + i"
    >
      <v-card class="my-5" :loading="$store.state.poi.loading">
        <div class="pa-4 font-weight-bold">
          {{ mainCategory.category }}
        </div>
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(category, i) in mainCategory.content"
            :key="category.label + i"
          >
            <v-expansion-panel-header>
              {{ category.label }}
              <v-spacer></v-spacer>

              <div
                v-if="
                  category.label == 'In Planung' ||
                  category.label == 'In Umsetzung'
                "
                class="text-right"
              >
                <v-btn
                  color="primary"
                  icon
                  small
                  @click="goToManagePage(category.label)"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-if="category.type == 'submenu'">
                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(menu, i) in category.layer"
                    :key="menu.label + i"
                  >
                    <v-expansion-panel-header>
                      {{ menu.label }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div v-if="menu.type == 'submenu'">
                        <v-expansion-panels>
                          <v-expansion-panel
                            v-for="(submenu, i) in menu.layer"
                            :key="submenu.label + i"
                          >
                            <v-expansion-panel-header>
                              {{ submenu.label }}
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-checkbox
                                v-for="(subsubmenu, i) in submenu.layer"
                                :key="subsubmenu.label + i"
                                :label="subsubmenu.label"
                                :value="subsubmenu.value"
                                v-model="selectedFilterValue"
                                @change="filterValueChanged"
                              ></v-checkbox>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </div>
                      <div v-else>
                        <!-- <v-checkbox
                            v-for="(submenu, i) in menu.layer"
                            :key="submenu.label + i"
                            :label="submenu.label"
                            :value="submenu.value"
                            v-model="selectedFilterValue"
                            @change="filterValueChanged"
                        ></v-checkbox> -->
                        <v-checkbox
                          v-for="(submenu, i) in menu.layer"
                          :key="submenu.label + i"
                          :label="submenu.label"
                          :value="submenu.value"
                          :input-value="
                            $store.state.categories.selectedFilterValue
                          "
                          @change="filterValueChanged(submenu)"
                        ></v-checkbox>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
              <div v-else>
                <!-- <v-checkbox
                    v-for="(menu, j) in category.layer"
                    :key="menu.label + j"
                    :label="menu.label"
                    :value="menu.value"
                    v-model="selectedFilterValue"
                    @change="filterValueChanged"
                ></v-checkbox> -->
                <template v-if="mainCategory.category == 'Ladeinfrastruktur'">
                  <!--<div v-if="category.label == 'In Betrieb'">
                    <div
                      v-for="(menu, j) in category.layer"
                      :key="menu.label + j"
                    >
                      <CustomRadius
                        :submenu="menu.value"
                        class="mx-1 mt-2 mb-5"
                      />
                      <v-checkbox
                        :label="menu.label"
                        :value="menu.value"
                        :input-value="
                          $store.state.categories.selectedFilterValue
                        "
                        @change="filterValueChanged(menu)"
                      ></v-checkbox>
                    </div>
                  </div>-->
                  <div>
                    <CustomRadius
                      :key="'customRadius' + category.label"
                      :submenu="category"
                      class="mx-1 mt-2 mb-5"
                    />
                    <v-checkbox
                      v-for="(menu, j) in category.layer"
                      :key="menu.label + j"
                      :label="menu.label"
                      :value="menu.value"
                      :input-value="$store.state.categories.selectedFilterValue"
                      @change="filterValueChanged(menu)"
                    >
                    </v-checkbox>
                  </div>
                </template>

                <v-checkbox
                  v-else
                  v-for="(menu, j) in category.layer"
                  :key="menu.label + j"
                  :label="menu.label"
                  :value="menu.value"
                  :input-value="$store.state.categories.selectedFilterValue"
                  @change="filterValueChanged(menu)"
                ></v-checkbox>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </div>
  </div>
</template>

<script>
import CustomRadius from "./CustomRadius.vue";

export default {
  components: {
    CustomRadius,
  },

  data() {
    return {
      selectedFilterValue: [],
      mapType: "roadmap",
      mapTypes: [
        { label: "Roadmap", value: "roadmap" },
        { label: "Terrain", value: "terrain" },
        { label: "Satellite", value: "satellite" },
        { label: "Hybrid", value: "hybrid" },
      ],
    };
  },

  methods: {
    mapTypeChanged(event) {
      this.$store.commit("setMapType", event);
    },

    filterValueChanged(val) {
      this.$store.commit("updateFilterValue", val.value);
    },

    resetFilter() {
      this.$store.commit("resetFilterValue");
    },

    goToManagePage(param) {
      this.$router
        .push({ path: "/manage", query: { filter: param } })
        .catch((e) => {
          console.error(e.message);
        });
    },
  },

  watch: {
    "$store.state.auth.user": {
      handler: function (user) {
        if (user) {
          this.$store.dispatch("attachCategoriesOnSnapshot");
        }
      },
      immediate: true,
    },
  },

  computed: {
    dividedCategories() {
      return this.$store.getters.getDividedCategories;
    },
  },
};
</script>
