import Vue from 'vue'

const state = {
    isSignedIn: false,
    isAuthenticating: false,
    user: null,
    profile: null,
    authClaims: {
        admin: false
    },
    isCookie: false
}

const getters = {
    aid(state) {
        return state.profile.employee?.aid
    },

    email(state) {
        return state.profile.email
    }
}

const mutations = {
    setCookieState(state, cookieState) {
        state.isCookie = cookieState
    },
    setIsAuthenticating(state, isAuthenticating) {
        state.isAuthenticating = isAuthenticating
    },

    setUserLogin(state, user) {
        state.user = user
    },

    setProfile(state, profile) {
        Vue.set(state, "profile", profile)
    },

    setUserLogout(state) {
        state.user = null
        state.profile = null
    },

    setAuthClaims(state, claims) {
        state.authClaims.admin = claims.permissions.admin == true
    }
}

export default { state, getters, mutations }
