import Vue from "vue";
import { collection, onSnapshot, query } from "firebase/firestore";

import ls from "../helper/localStorageService";
import { db } from "@/firebase";

const state = {
  categories: [],
  selectedFilterValue: [],
  firestoreListener: null,
  isLoading: false,
  mappingCategories: [
    { main: "Stempelgeraete", sub: ["Stempelgerät"] },
    { main: "NEW Gebäude", sub: ["NEW Gebäude"] },
    { main: "In Planung", sub: ["Ladesäule", "Schnellladesäule"] },
    { main: "In Umsetzung", sub: ["Ladesäule", "Schnellladesäule"] },
  ],
};

const getters = {
  getSubCategories(state, getters, rootState, rootGetters) {
    return state.mappingCategories
      .filter((category) => {
        return rootGetters.getUserWriteRights.includes(category.main);
      })
      .map((category) => category.sub)
      .flat();
  },

  getMainCategory:
    (state) =>
    (subCategory = "Stempelgerät") => {
      const foundCategory = state.mappingCategories.find((category) => {
        return category.sub.includes(subCategory);
      });
      if (foundCategory) {
        return foundCategory.main;
      }
      return "Unbekannt";
    },

  getDividedCategories(state, getters, rootState, rootGetters) {
    return state.categories
      .filter((category) => {
        return rootGetters.getUserReadRights.includes(category.label);
      })
      .reduce((mainAcc, mainCur) => {
        const { category, ...rest } = mainCur;
        const foundIndex = mainAcc.findIndex((obj) => obj.category == category);
        if (foundIndex >= 0) {
          mainAcc[foundIndex].content.push({ ...rest });
        } else {
          mainAcc.push({
            category,
            content: [{ ...rest }],
          });
        }
        return mainAcc;
      }, []);
  },
};

const mutations = {
  setCategories(state, categories) {
    state.categories = categories;
  },

  // setSelectedFilterValue(state, filterValue) {
  //     // localStorage.setItem('filterValue', JSON.stringify(filterValue))
  //     state.selectedFilterValue = filterValue
  // },

  // addFilterValue(state, value) {
  //     // console.log('STATE ADD', value)

  //     state.selectedFilterValue.push(value)
  // },

  // removeFilterValue(state, value) {
  //     // console.log('STATE REMOVE', value)
  //     const newValue = state.selectedFilterValue.filter(val => val != value)
  //     state.selectedFilterValue = newValue
  // },

  updateFilterValue(state, value) {
    if (state.selectedFilterValue.includes(value)) {
      const newVal = state.selectedFilterValue.filter(
        (filterValue) => filterValue != value
      );
      Vue.set(state, "selectedFilterValue", newVal);
    } else {
      state.selectedFilterValue.push(value);
    }
    ls.saveToStorage("filterValue", state.selectedFilterValue);
  },

  resetFilterValue(state) {
    Vue.set(state, "selectedFilterValue", []);
  },

  initSelectedFilterState(state) {
    if (typeof localStorage.filterValue !== "undefined") {
      state.selectedFilterValue = JSON.parse(localStorage.filterValue);
    }
  },
};

const actions = {
  attachCategoriesOnSnapshot({ state }) {
    return new Promise((resolve, reject) => {
      if (state.firestoreListener) {
        resolve(state.categories);
        return;
      }
      // const zeit = DateTime.now().setLocale('de').toFormat("HH:mm")
      // objectTypes.forEach(type => {
      // for (const type of objectTypes) {
      // console.log(objectTypes)

      state.isLoading = true;
      const q = query(collection(db, "layer"));
      state.firestoreListener = onSnapshot(
        q,
        { includeMetadataChanges: true },
        (snapshot) => {
          state.categories = [];
          snapshot.docs.forEach((doc) => {
            if (doc.exists) {
              state.categories.push({ ...doc.data(), docid: doc.id });
            }
          });
          state.isLoading = false;
          console.log("CATEGORIES", state.categories);
          resolve(state.categories);
        },
        (error) => {
          console.log(error.message);
          reject(error);
        }
      );
      // }
      // });
    });
  },

  loadDefaultFilterValue({ state }) {
    const filterValue = ls.checkStorage("filterValue");
    if (filterValue?.length) {
      Vue.set(state, "selectedFilterValue", filterValue);
    }
  },
};

export default { state, getters, mutations, actions };
