<template>
  <div v-if="show" id="routeplanner">
    <v-btn
      :class="{ 'animate-scale': animate }"
      @click="$router.push({ name: 'route-planner' })"
      fab
      bottom
      right
      color="primary"
    >
      <v-icon>mdi-map-marker-distance</v-icon>
    </v-btn>
    <span>{{ routes.length }}</span>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("poi", ["routes"]),
    show() {
      const condA = this.$route.name != "route-planner";
      const condB = this.routes.length > 0;
      return condA && condB;
    },
  },
  data() {
    return {
      animate: false,
    };
  },
  watch: {
    routes: {
      handler() {
        this.animate = true;
        setTimeout(() => {
          this.animate = false;
        }, 500); // time of the animation
      },
      immediate: false,
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#routeplanner {
  position: fixed;
  bottom: 1rem;
  right: 3.5rem;
  z-index: 1000;
  span {
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    font-size: 0.8rem;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .v-btn.animate-scale {
    animation: scaleAnim 0.5s ease-in-out;
  }
}

@keyframes scaleAnim {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
