import { collection, onSnapshot, query } from "firebase/firestore";

import { db } from '@/firebase'

import { getHauptKategorie } from '../helper/helper';

const state = {
    firestoreListener: null,
    isLoading: false,
    userRights: []
}

const getters = {
    isAdmin: (state) => (aKennung) => {
        return state.userRights.find(right => right.docid == 'admin').users.includes(aKennung.toLowerCase())
    },

    userRightsLowercase(state) {
        return state.userRights.map(right => {
            return {
                ...right,
                users: right.users.map(user => user.toLowerCase())
            }
        })
    },

    getUserReadRights(state, getters, rootState, rootGetters) {
        return getters.userRightsLowercase
            .filter(right => {
                if (rootGetters.aid) {
                    return right.docid != 'admin' && right.users.includes(rootGetters.aid.toLowerCase())
                }
                return right.docid != 'admin' && right.users.includes(rootGetters.email.toLowerCase())
            })
            .map(right => {
                return right.docid
            })
    },

    hauptKategorie(state,getters,rootState,rootGetters) {
        return getters.userRightsLowercase
            .filter(right => {
                if (rootGetters.aid) {
                    return right.docid != 'admin' && right.users.includes(rootGetters.aid.toLowerCase())
                }
                return right.docid != 'admin' && right.users.includes(rootGetters.email.toLowerCase())
            })
            .map(right => right.docid)
            .reduce((acc,cur)=>{
                const convertedCategory = getHauptKategorie(cur)
                if(!acc.includes(convertedCategory)) {
                    acc.push(convertedCategory)
                }
                return acc
            },[])
    },

    getUserWriteRights(state, getters, rootState, rootGetters) {
        return getters.userRightsLowercase
            .filter(right => {
                if (rootGetters.aid) {
                    return right.docid != 'admin' && right.users.includes(rootGetters.aid.toLowerCase()) && !right.readOnly
                }
                return right.docid != 'admin' && right.users.includes(rootGetters.email.toLowerCase()) && !right.readOnly
            })
            .map(right => {
                return right.docid
            })
    },

    // convertUserReadRightsToObjectType(_, getters) {
    //     return getters.getUserReadRights.map(right => {
    //         switch (right) {
    //             case 'Stempelgeraete': return ['Stempelgerät']
    //             case 'Stationen': return ['Ortsnetzstation', 'Kundenstation', 'Gemeinschaftsstation', 'MS Schrank', 'Verteiler SA']
    //             case 'Kabelverteiler': return ['NS Kabelverteiler']
    //             case 'Schaltstellen': return ['BL Schaltstelle']
    //             case 'Umspannanlagen': return ['Umspannanlage']
    //         }
    //     }).flat()
    // },

    // getUserWriteRights(_, getters) {
    //     return getters.getUserReadRights.map(right => {
    //         switch (right) {
    //             case 'Stempelgeraete': return ['Stempelgerät']
    //         }
    //     }).flat()
    // }
}

const actions = {
    attachUserRightsOnSnapshot({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.userRights);
                return;
            }
            state.isLoading = true;
            // const zeit = DateTime.now().setLocale('de').toFormat("HH:mm")
            const q = query(collection(db, 'config', 'users', 'permission'));
            state.firestoreListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    state.userRights = []

                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            state.userRights.push({ ...doc.data(), docid: doc.id });
                            // state.userRights[doc.id] = { ...doc.data() }
                        }
                    });
                    state.isLoading = false;
                    resolve(state.userRights);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },
}

export default { state, getters, actions }
