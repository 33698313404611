const getHauptKategorie = (category) => {
  switch (category) {
    case "Bezirksregelanlage":
    case "Erdgastankstelle":
    case "Kundenanlage":
    case "Kombianlage":
    case "Übergabestation":
      return "Gas";
    case "Stationen":
    case "Kabelverteiler":
    case "Schaltstellen":
    case "Umspannanlagen":
      return "Strom";
    default:
      return category;
  }
};

export { getHauptKategorie };
